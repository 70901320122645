
.select-template-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    padding: 2rem;
    background-color: #f9f9f9;
  
    .progress-bar {
      width: 100%;
      max-width: 800px;
      margin-bottom: 2rem;
    }
  
    .template-options {
      display: flex;
      gap: 1rem;
      margin-bottom: 2rem;
  
      button {
        padding: 0.75rem 1.5rem;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1rem;
        transition: background 0.3s ease;
  
        &:first-child {
          background-color: #4caf50;
          color: white;
        }
  
 
        &:hover {
          opacity: 0.8;
        }
  
        &.active {
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
          background-color: #0293a8;
          color: white;
        }
      }
    }
  
    .editor-container {
      width: 100%;
      padding: 1rem;
      border-radius: 12px;
      box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
      background: white;
    }
  }
  