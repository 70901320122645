* {
    scroll-behavior: smooth;
  }
  
  .progress-con {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .progress-bar {
      width: 15vw;
      height: 2em;
    }
  
    .progrees-status {
      font-size: 20px;
      font-weight: 500;
      margin-left: 2rem;
    }
  }
  
  .controlerBtnContainer {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .reminder {
    display: none;
    font-size: 16px;
    font-weight: 700;
    color: red;
    text-align: center;
  }
  
  .bg-container {
    width: 100vw;
    height: calc(100vh - 100px);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    background-color: #f5f5f5;
  
    .side-nav {
      width: 200px;
      height: calc(100vh - 80px);
      background-color: #fff;
      display: flex;
      flex-direction: column;
      padding-top: 3em;
  
      .nav-item {
        margin-left: 50px;
        font-size: 18px;
        color: #999999;
        font-weight: 600;
        margin-top: 1rem;
      }
    }
  
    .editor {
      padding-top: 1em;
      padding-bottom: 1em;
      width: 100%;
      height: calc(100vh - 96px);
      overflow: hidden;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .controlers-container {
        width: 100%;
        gap: 10px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 2em;
  
        .view-btn {
          font-size: 28px;
        }
  
        .controller {
          font-size: 22px;
          margin-right: 1em;
        }
  
        .controller-btn {
          border: 2px solid #0293a8;
          font-weight: bold;
          color: white;
          background-color: #0293a8;
          border-radius: 20px;
          padding: 5px 20px;
        }
      }
  
      .temp-container {
        margin-top: 1em;
        background-color: #ffff;
        display: flex;
        flex-direction: column;
        align-items: center;
  
        .contact-data-2 {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 45px;
          background-color: #ebee55;
          border-bottom: 1px solid #999999;
  
          .email,
          .phone-number {
            background-color: #ffffff;
            padding: 5px;
            color: #3f3f3f;
            outline: none;
            border: none;
            border-radius: 8px;
          }
        }
  
        .header-container-2 {
          width: 100%;
          height: 80px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          background-color: #ebee55;
  
          .logo-store-name-con {
            display: flex;
            align-items: center;
  
            .image-label {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              position: relative;
              margin-right: 1em;
              background-size: cover;
  
              .logo {
                position: absolute;
                border-radius: 50%;
                height: 60px;
                width: 60px;
              }
  
              .add-con {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 60px;
                width: 60px;
                background-color: #ffffff;
                border-radius: 50%;
  
                .add-logo {
                  font-size: 20px;
                }
  
                .add-text {
                  font-size: 10px;
                  text-align: center;
                }
              }
            }
  
            .store-name {
              background-color: #ffffff;
              padding: 6px;
              border: none;
              border-radius: 8px;
              color: #3d3d3d;
              font-weight: 600;
              outline: none;
            }
          }
  
          .tabs {
            display: flex;
            align-items: center;
            gap: 10px;
  
            .tab {
              font-size: 35px;
              background-color: #ffffff;
              border-radius: 50%;
              padding: 8px;
            }
          }
        }
  
        .banner-bg-container {
          width: 100%;
          background-color: #d9d9d9;
          margin-bottom: 1rem;
  
          .banner {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            background-size: cover;
            background-position: center;
  
            .banner-tab {
              margin: 1rem;
              background-color: #d9d9d9;
              box-shadow: 0px 0px 5px #999999;
              height: 3.5em;
              width: 3.5em;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: relative;
              border-radius: 8px;
              cursor: pointer;
  
              .add-con {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
  
              .tab-img {
                border-radius: 8px;
                border: none;
              }
  
              .add-text {
                font-size: 10px;
              }
  
              .remove-btn {
                position: absolute;
                top: -10px;
                left: 90%;
                font-size: 11px;
                background-color: #f5f5f5;
                height: 15px;
                width: 15px;
                border: none;
                border-radius: 50%;
                cursor: pointer;
              }
            }
          }
        }
  
        .team-container {
          width: 100%;
          height: 50vh;
          display: flex;
          justify-content: center;
          align-items: center;
  
          .team-detail-container {
            width: 80%;
            background-color: #f3f3f3;
            border-radius: 6px;
            padding: 10px;
  
            .team-h-bg-con {
              display: flex;
              justify-content: flex-end;
  
              .team-h-con {
                width: 60%;
                display: flex;
                justify-content: space-between;
                align-items: center;
  
                .team-heading {
                  font-size: 22px;
                  font-weight: bold;
                  display: flex;
                  justify-content: center;
                }
  
                .add-btn {
                  border: 2px solid #0293a8;
                  text-align: center;
                  padding: 5px 20px;
                  margin-top: 10px;
                  border-radius: 3px;
                  color: #0293a8;
                  font-weight: bold;
                }
              }
            }
  
            .teams {
              display: flex;
              overflow-x: scroll;
              overflow-y: hidden;
              margin-top: 10px;
  
              .team-member-details {
                display: flex;
                flex-direction: column;
                align-items: center;
  
                .team-member-img-div {
                  border-radius: 10px;
  
                  .team-member-image {
                    width: 100%;
                  }
  
                  .without-image {
                    height: 100px;
                    width: 100px;
                    object-fit: contain;
                    border-radius: 50%;
                    overflow: hidden;
  
                    .without-img-uploads {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 100%;
                      height: 100%;
                      background-color: #d9d9d9;
                      border-radius: 50%;
                      cursor: pointer;
  
                      .iconss {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                      }
                    }
                  }
                }
  
                .input-details {
                  margin-top: 0.5rem;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
  
                  .input-box {
                    flex-grow: 0;
  
                    .input-name {
                      display: flex;
                      justify-content: center;
                      outline: none;
                      background-color: #f3f3f3;
                      color: black;
                      width: 100%;
                      font-weight: 600;
  
                      &::placeholder {
                        text-align: center;
                      }
                    }
                  }
  
                  .input-position {
                    background-color: #f3f3f3;
                    font-size: 14px;
                    outline: none;
                    width: 100%;
  
                    &::placeholder {
                      text-align: center;
                    }
                  }
                }
              }
            }
  
            .addteam {
              color: #0293a8;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-weight: bold;
              margin-top: 2rem;
              cursor: pointer;
              border: 1px solid #0293a8;
              padding: 1rem;
              border-radius: 8px;
              height: 20vh;
  
              .plus {
                font-size: 50px;
              }
            }
          }
        }
      }
  
      .button-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 1em;
  
        .headin {
          font-size: 22px;
          font-weight: bold;
          text-align: center;
        }
  
        .without-heading {
          width: 70%;
          display: grid;
          grid-template-columns: 40% 57%;
          justify-content: space-between;
          margin-top: 2rem;
          margin-bottom: 3rem;
  
          .btn-label {
            height: 100px;
            border: 2px solid #0293a8;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #d9d9d9;
          }
  
          .bag {
            border-radius: 4px;
            height: 49vh;
          }
  
          .button-details {
            font-size: 16px;
            font-weight: 500;
  
            .text-area-store-btn {
              width: 100%;
              border: 2px solid #bcbcbc;
              border-radius: 10px;
              padding: 10px;
              font-size: 14px;
              outline: none;
            }
  
            .button-list {
              display: grid;
              grid-template-columns: 48% 48%;
              justify-content: space-between;
              gap: 10px;
  
              .button-description {
                width: 100%;
                display: grid;
                grid-template-columns: 20% 80%;
                border: 2px solid #0293a8;
                border-radius: 6px;
                height: 40px;
                gap: 10px;
                font-size: 14px;
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
  
                .btn-icon {
                  width: 28px;
                  height: 28px;
                  margin-left: 0.5rem;
                }
  
                .btn-icon-duk {
                  width: 28px;
                  height: 28px;
                  margin-left: 0.5rem;
                  border-radius: 5px;
                }
  
                .btn-input {
                  width: 80%;
                  border-radius: 4px;
                  outline: none;
                  margin-left: 0.5rem;
                }
              }
            }
          }
        }
      }
  
      .footer-container {
        width: 100%;
        background-color: #3f3f3f;
        display: flex;
        justify-content: space-evenly;
  
        .about-address {
          width: 45%;
          margin-top: 2rem;
          color: #3f3f3f;
          padding: 10px;
  
          .label {
            font-size: 16px;
            font-weight: 600;
            color: white;
          }
  
          .about {
            width: 90%;
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
  
            .text-area {
              border-radius: 5px;
              padding: 1rem;
              outline: none;
              background-color: #ffffff;
            }
          }
  
          .address {
            display: flex;
            flex-direction: column;
            width: 90%;
  
            .input-value {
              height: 40px;
              border-radius: 5px;
              padding: 1rem;
              outline: none;
              background-color: #ffffff;
            }
          }
  
          .copyright {
            text-align: start;
            margin-top: 1.5rem;
            margin-bottom: 3rem;
            color: white;
          }
        }
  
        .terms-msme {
          display: flex;
          justify-content: space-between;
          margin-top: 2rem;
  
          .terms-condition-msme {
            width: 45%;
  
            .heading {
              font-size: 16px !important;
              color: white;
              font-weight: 600;
            }
  
            .terms-msme-image {
              width: 100%;
              height: 20vh;
              border-radius: 5px;
            }
  
            .without-terms-msme {
              height: 20vh;
  
              .without-uploads {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background-color: #d9d9d9;
                border-radius: 5px;
                cursor: pointer;
  
                .icons {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }
  
        .copyright-mobile {
          text-align: center;
          margin-top: 2rem;
          color: white;
        }
      }
    }
  }
  
  .add-con:hover {
    opacity: 1 !important;
  }
  
  .social-link {
    background-color: transparent;
    border-bottom: 0px solid;
    outline: none;
    flex-grow: 2;
    color: #fff;
  
    &::placeholder {
      color: #fff;
    }
  }
  
  @media screen and (width <= 620px) {
    .reminder {
      display: unset;
    }
  
    .bg-container {
      flex-direction: column;
  
      .side-nav {
        flex-direction: row;
        height: unset;
        width: unset;
        padding: 10px;
        justify-content: space-around;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  
        .nav-item {
          margin: unset;
        }
      }
  
      .editor .controlers-container {
        padding: unset;
        gap: 30px;
      }
  
      .side-nav .nav-item {
        font-size: 16px !important;
        padding: unset;
      }
  
      .editor .controlers-container {
        justify-content: center !important;
      }
    }
  }
  
  @media screen and (width <= 360px) {
    .bg-container .editor .temp-container {
      width: 300px !important;
    }
  }